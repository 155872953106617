import axios from "axios";
import { APP_VERSION, DATALAKE_URL } from "./constant";

export const logErrorToDatalake = async (
  customerId,
  exception,
  eventName,
  urlName,
  eventType
) => {
  const payload = {
    customerId: customerId || null,
    logEventType: eventType,
    source: "webapp",
    appVersion: APP_VERSION,
    screenName: urlName,
    eventName,
    errorMessage: exception,
  };
  const res = await axios.post(DATALAKE_URL, payload); 
  // datalake url is https://earnpay.earlysalary.com/exception-log
  console.log("Exception Data :", res);
};