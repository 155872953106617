import  axiosClient  from '../config/axiosConfig'

export const userlogin = (address) => {
    return axiosClient(`user/login/${address}`,{},'GET');
}


// export const getCategoryList = (address) => {
//     return axiosClient(`user/login/${address}`,{},'GET');
// }

export const createCollection = (payload,token) => {
    return axiosClient('collection/create',payload,'POST');
}

// export const getCategoryList = (address) => {
//     return axiosClient(`user/login/${address}`,{},'GET');
// }









