import axios from "axios";
import { BASEURL } from "../utils/constant";
import { logErrorToDatalake } from "../utils/errorLogging";
//import { decryptLocalStorage } from "../utils/decryptor";

const client = axios.create({});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const errorResponse = JSON.parse(JSON.stringify(error));
        logErrorToDatalake(
            // localStorage.getItem("customerid"),
            //decryptLocalStorage("customerid"),
            JSON.stringify(error),
            errorResponse.config.url,
            errorResponse.config.url,
            "error"
        );
        return Promise.reject(error);
    }
);

  const axiosClient = (
    endpoint,
    payload = {},
    method = "get",
    headers={}
    // headers = {
    //      //Authorization: localStorage.getItem("token"),
    //      Authorization: `Bearer ${token}`,
    //     // customerid: localStorage.getItem("customerid"),
    //     //authorization: decryptLocalStorage("token"),
    //     //customerid: decryptLocalStorage("customerid"),
    // }
) => {
    let axiosConfig = {
        method: method.toLowerCase(),
    };
    if (
        // localStorage.getItem("token") !== null &&
        // localStorage.getItem("token") !== undefined &&
        //decryptLocalStorage("token") !== null &&
        //decryptLocalStorage("token") !== undefined &&
        endpoint !== "login" &&
        endpoint !== "verifyOtp"
    ) {
        axiosConfig.headers = headers;
    }
    if (method === "get") {
        axiosConfig.params = payload;
    } else {
        axiosConfig.data = payload;
    }

    return client(`${BASEURL}${endpoint}`, axiosConfig);
};
export default axiosClient;

