
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import { Metamask_comp_login } from "../../components/metamask/Metamask";
import Image from "next/image";
import { setLoginDetails } from "../../redux/counterSlice";
import { ERROR, Warning } from '../../utils/errors';
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from 'next/router';
import { CONNECT_WALLET } from '../../components/metamask/connector';
import { userlogin } from "../../services/apiServices";
import useTranslation from "next-translate/useTranslation";
const Login = () => {
    const { t, lang } = useTranslation('common');
    const dispatch = useDispatch();
    const router = useRouter();
    const [itemActive, setItemActive] = useState(1);
    const loginDetails = useSelector((state) => state.counter.loginDetails);
    const [ConnectWalletAddress, setConnectWalletAddress] = useState("");
    const tabItem = [
        {
            id: 1,
            text: "Ethereum",
            icon: "ETH"
        },
    ];
    useEffect(() => {
        const { ethereum } = window;
        if (ethereum) {
            ethereum.on('accountsChanged', function (accounts) {
                if (accounts.length > 0) {
                    connectWallet();
                }
                else {
                    disConnectWallet();
                }
            });
        }
        setConnectWalletAddress(loginDetails?.address);
    }, [loginDetails]);
    const disConnectWallet = () => {
        dispatch(setLoginDetails({
            address: "",
            isLogin: false
        }));
        setConnectWalletAddress('');
    };
    const connectWallet = async () => {
        const res = await CONNECT_WALLET();
        if (res.status) {
            try {
                const { data: response } = await userlogin(res.address);
                if (response.status === true) {
                    dispatch(setLoginDetails({
                        address: res.address,
                        balance: res.balance,
                        isLogin: true,
                        profile: response.data
                    }));
                    localStorage.setItem('artnxsUser', JSON.stringify(response?.data));
                    setConnectWalletAddress(res.address);
                    router.push('/');
                }
                else {
                    Warning(response.message);
                }
            }
            catch (error) {
                console.log('error', error);
            }
        }
        else {
            ERROR(res.message);
        }
    };
    return (<div>
      <Meta title="Login"/>
      {/* <!-- Login --> */}
      <section className="relative h-screen">
        <div className="lg:flex lg:h-full pt-[100px]">
          {/* <!-- Left --> */}
          <div className="relative text-center lg:w-1/2">
            <Image width={768} height={722} src="/images/new-login-img.png" alt="login" className="absolute h-full w-full object-containt"/>
            {/* <!-- Logo --> */}
            {/* <Link href="/" className="relative inline-block py-[100px]">
          <Image
            width={130}
            height={30}
            src="/logo.jpg"
            className="inline-block object-cover"
            alt="ARTNXS | NFT Marketplace"
          />
        </Link> */}
          </div>

          {/* <!-- Right --> */}
          <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <Image width={1519} height={773} priority src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full object-cover"/>
            </picture>

            <div className="w-full max-w-[25.625rem] text-center">
              <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                {t('sign_in')}
              </h1>
              <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                {t('signin_description')}
              </p>

              {/* <!-- Tabs Nav --> */}
              <Tabs className="tabs ">
                <TabList className="no-scrollbar nav nav-tabs scrollbar-custom dark:border-jacarta-600 border-jacarta-100 mb-12 flex items-center justify-start overflow-x-auto overflow-y-hidden border-b pb-px md:justify-center">
                  {tabItem.map(({ id, text, icon }) => {
            return (<Tab className="nav-item" key={id} onClick={() => setItemActive(id)}>
                        <button className={itemActive === id
                    ? "nav-link gap-2 items-center active hover:text-jacarta-700 text-jacarta-400 relative flex whitespace-nowrap py-3 px-6 dark:hover:text-white"
                    : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"}>
                          <div className="relative w-4 h-4">
                            <Image src='/polygon.png' fill className="w-full h-full object-cover"/>
                          </div>

                          <span className="font-display text-base font-medium">
                            Polygon
                          </span>
                        </button>
                      </Tab>);
        })}
                </TabList>

                {/* <!-- Ethereum --> */}
                <TabPanel>
                  <div className="tab-pane fade show active">
                    {/* <Metamask_comp_login /> */}

                    <button onClick={connectWallet} className="js-wallet bg-black dark:bg-white dark:text-black mb-4 flex w-full items-center justify-center rounded-full border-2 border-transparent hover:bg-accent-dark hover:dark:bg-accent-dark hover:dark:text-white py-4 px-8 text-center font-semibold text-white transition-all">
                      <Image src="/images/wallets/metamask_24.svg" className=" inline-block h-6 w-6" alt="icon" height={24} width={24}/>
                      <span className="ml-2.5">{t('metamask_signin')}</span>
                    </button>
                    {/* <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
          <Image
            width={24}
            height={24}
            src="/images/wallets/torus_24.svg"
            className="mr-2.5 inline-block h-6 w-6 object-contain"
            alt="icon"
          />
          <span>Torus</span>
        </button> */}

                    {/* <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
          <Image
            width={24}
            height={24}
            src="/images/wallets/wallet_connect_24.svg"
            className="mr-2.5 inline-block h-6 w-6 object-contain"
            alt="login"
          />
          <span>Mobile Wallet</span>
        </button> */}

                    {/* <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
          <span>Show more options</span>
        </button> */}
                  </div>
                </TabPanel>
                {/* <!-- Ethereum end --> */}

                {/* <!-- Torus --> */}
                <TabPanel>
                  <div className="tab-pane fade" id="torus" aria-labelledby="torus-tab">
                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <Image width={24} height={24} src="/images/wallets/torus_24.svg" className="mr-2.5 inline-block h-6 w-6" alt="wallet"/>
                      <span>Torus</span>
                    </button>

                    <Metamask_comp_login />

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <Image width={24} height={24} src="/images/wallets/wallet_connect_24.svg" className="mr-2.5 inline-block h-6 w-6" alt="wallet"/>
                      <span>Mobile Wallet</span>
                    </button>

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <span>Show more options</span>
                    </button>
                  </div>
                </TabPanel>
                {/* <!-- Torus end --> */}

                {/* <!-- Wallet Connect --> */}
                <TabPanel>
                  <div className="tab-pane fade">
                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <Image width={24} height={24} src="/images/wallets/wallet_connect_24.svg" className="mr-2.5 inline-block h-6 w-6" alt=""/>
                      <span>Mobile Wallet</span>
                    </button>

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <Image width={24} height={24} src="/images/wallets/torus_24.svg" className="mr-2.5 inline-block h-6 w-6" alt=""/>
                      <span>Torus</span>
                    </button>

                    <Metamask_comp_login />

                    <button className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
                      <span>Show more options</span>
                    </button>
                  </div>
                </TabPanel>
                {/* <!-- Wallet Connect --> */}
              </Tabs>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end login --> */}
    </div>);
};
export default Login;

    async function __Next_Translate__getStaticProps__18dc829effb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18dc829effb__ as getStaticProps }
  